import { useCallback } from 'react';
import { TClassifcationOption } from 'repositories/aecomStrapiCms/classificationOption/types';
import { TProductsListing } from 'repositories/aecomStrapiCms/product/types';
import { DropdownOrder, TSelectedOptionsFilter } from './Products.types';

export interface GroupFilter {
  [key: string]: TClassifcationOption[];
}

export const useProductsHooks = () => {
  const groupFilters = useCallback((data: TClassifcationOption[]) => {
    const grouped: GroupFilter = {};

    (data || []).forEach((item) => {
      const groupName =
        item.attributes.classification_group.data?.attributes?.name;

      if (!grouped[groupName]) {
        grouped[groupName] = [];
      }

      grouped[groupName].push(item);

      grouped[groupName].sort((a, b) => {
        const nameA = a.attributes.name.toLowerCase();
        const nameB = b.attributes.name.toLowerCase();
        return nameA.localeCompare(nameB);
      });
    });

    const sortedGrouped = Object.fromEntries(
      Object.entries(grouped).sort(([, groupA], [, groupB]) => {
        const orderA =
          groupA[0].attributes.classification_group.data?.attributes?.order;
        const orderB =
          groupB[0].attributes.classification_group.data?.attributes?.order;
        return orderA - orderB;
      }),
    );

    return sortedGrouped;
  }, []);

  const getFiltersToQuery = useCallback(
    (selectedOptionsFilter: TSelectedOptionsFilter) => {
      const queryFilters: string[] = [];
      Object.entries(selectedOptionsFilter).forEach(([slug, value]) => {
        if (value) {
          queryFilters.push(slug);
        }
      });

      return queryFilters;
    },
    [],
  );

  const getSortToQuery = useCallback((selectedOrder: DropdownOrder) => {
    const options = {
      [DropdownOrder.LowestPrice]: {
        minimal_price: 'asc',
      },
      [DropdownOrder.BiggestPrice]: {
        minimal_price: 'desc',
      },
      [DropdownOrder.BestSellers]: {
        ranking: 'asc',
      },
    };

    return options[selectedOrder];
  }, []);

  const getSearchedProducts = useCallback(
    (productsListing: TProductsListing[]) => {
      return (productsListing || []).reduce((total, page) => {
        return total + (page.data || []).length;
      }, 0);
    },
    [],
  );

  return {
    groupFilters,
    getFiltersToQuery,
    getSortToQuery,
    getSearchedProducts,
  };
};
