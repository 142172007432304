import { Dispatch, SetStateAction, useCallback } from 'react';
import { DropdownOrder } from '@/screens/main/Products';

interface ProductSubHeaderProps {
  setSelectedOrder: Dispatch<SetStateAction<DropdownOrder>>;
}

export const useProductSubHeaderHooks = ({
  setSelectedOrder,
}: ProductSubHeaderProps) => {
  const handleOnSelected = (e: Event) => {
    const value = e.target as HTMLInputElement;
    setSelectedOrder((value.value || value.textContent) as DropdownOrder);
  };

  const getDescription = useCallback(
    (searchedProducts: number, totalProducts: number) => {
      return `${searchedProducts} de ${totalProducts} ${searchedProducts > 1 || searchedProducts === 0 ? 'produtos' : 'produto'}`;
    },
    [],
  );

  return { handleOnSelected, getDescription };
};
