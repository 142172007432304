import { Dispatch, SetStateAction, useCallback } from 'react';
import { TSelectedOptionsFilter } from '@/screens/main/Products';
import { TClassifcationOption } from 'repositories/aecomStrapiCms/classificationOption/types';

interface ProductFiltersHooksProps {
  setSelectedOptionsFilter: Dispatch<SetStateAction<TSelectedOptionsFilter>>;
}

const useProductFiltersHooks = ({
  setSelectedOptionsFilter,
}: ProductFiltersHooksProps) => {
  const handleOptions = useCallback(
    (option: TClassifcationOption) => {
      const slug = option.attributes.slug;

      setSelectedOptionsFilter((prevState) => ({
        ...prevState,
        [slug]: !prevState[slug],
      }));
    },
    [setSelectedOptionsFilter],
  );

  const onClear = useCallback(() => {
    setSelectedOptionsFilter({});
  }, [setSelectedOptionsFilter]);

  const getShowButton = useCallback(
    (selectedOptionsFilter: TSelectedOptionsFilter) => {
      return Object.values(selectedOptionsFilter).some(
        (value) => value === true,
      );
    },
    [],
  );

  const getCountOptions = useCallback(
    (selectedOptionsFilter: TSelectedOptionsFilter) => {
      return Object.values(selectedOptionsFilter).filter(
        (value) => value === true,
      ).length;
    },
    [],
  );

  const getHeading = useCallback(
    (selectedOptionsFilter: TSelectedOptionsFilter) => {
      const appliedFilters = getCountOptions(selectedOptionsFilter);
      return `Filtros ${appliedFilters ? `(${appliedFilters})` : ''} `;
    },
    [getCountOptions],
  );

  return {
    handleOptions,
    onClear,
    getShowButton,
    getCountOptions,
    getHeading,
  };
};

export default useProductFiltersHooks;
