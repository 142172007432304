import media from '@mf/common/utils/styles/media';
import styled from 'styled-components';

export const SubHeader = styled.div`
  display: flex;
  gap: var(--spacing-3x);
  padding: var(--spacing-2_5x) var(--spacing-5x) var(--spacing-2_5x)
    var(--spacing-5x);
  align-self: stretch;
  align-items: flex-start;

  ${media.lessThan('mobile')} {
    padding: var(--spacing-2x);
    align-items: center;
    gap: var(--spacing-1x);

    .fs-row {
      display: flex;
    }

    .fs-col-sm-6 {
      width: 50%;
    }

    .flex-end {
      display: flex;
      justify-content: end;
    }
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  height: var(--spacing-6x);
  align-items: center;
`;

export const ProductsDropdownWrapper = styled.div`
  display: flex;
  height: 3rem;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const DropdownEnd = styled.div`
  display: flex;
  flex-direction: row-reverse;

  div[orientation='horizontal'] {
    z-index: var(--z-index-fixed);

    > div > div {
      z-index: var(--z-index-fixed);
      position: relative;
    }
  }
`;
