'use client';

import { MobileView } from '@mf/common/components/index';
import {
  FsBarFilter,
  FsCheckbox,
  FsCheckboxGroup,
  FsLoading,
  FsRadioButton,
  HeadingTag,
} from '@printi/ds-offset-react-core';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import strapiClassificationOption from 'repositories/aecomStrapiCms/classificationOption';
// eslint-disable-next-line import/order
import {
  DropdownOrder,
  TBlockedFilters,
  TSelectedOptionsFilter,
  useProductsHooks,
} from '@/screens/main/Products';
// eslint-disable-next-line import/order
import useProductFiltersHooks from './ProductFilters.hooks';
import * as S from './ProductFilters.styles';

interface ProductFiltersProps {
  selectedOptionsFilter: TSelectedOptionsFilter;
  setSelectedOptionsFilter: Dispatch<SetStateAction<TSelectedOptionsFilter>>;
  selectedOrder: DropdownOrder;
  setSelectedOrder: Dispatch<SetStateAction<DropdownOrder>>;
  blockedFilters?: TBlockedFilters;
}

export const ProductFilters = ({
  selectedOptionsFilter,
  setSelectedOptionsFilter,
  blockedFilters,
  selectedOrder,
  setSelectedOrder,
}: ProductFiltersProps) => {
  const [isClient, setIsClient] = useState<boolean>(false);
  const { useClassificationOptions } = strapiClassificationOption({
    baseUrl: process.env.BFF_API_BASE_URL,
  });
  const { groupFilters } = useProductsHooks();
  const { onClear, handleOptions, getShowButton, getHeading } =
    useProductFiltersHooks({
      setSelectedOptionsFilter,
    });

  const { data: options } = useClassificationOptions();

  useEffect(() => {
    setIsClient(true);
  }, []);

  return (
    <FsBarFilter
      heading={!isClient ? 'Filtros  ' : getHeading(selectedOptionsFilter)}
      headingProps={{ tag: HeadingTag.H2 }}
      clearButtonLabel="Limpar"
      onClearClick={onClear}
      className="filters"
      showButton={!isClient ? false : getShowButton(selectedOptionsFilter)}
    >
      {isClient && (
        <>
          <MobileView>
            <S.FsAccordionFilter label="Ordenar por" expanded>
              <S.RadioButtonGroup>
                <FsRadioButton
                  value={DropdownOrder.BestSellers}
                  selected={selectedOrder === DropdownOrder.BestSellers}
                  onChange={() => {
                    setSelectedOrder(DropdownOrder.BestSellers);
                  }}
                >
                  Mais vendidos
                </FsRadioButton>
                <FsRadioButton
                  value={DropdownOrder.LowestPrice}
                  selected={selectedOrder === DropdownOrder.LowestPrice}
                  onChange={() => {
                    setSelectedOrder(DropdownOrder.LowestPrice);
                  }}
                >
                  Menor preço
                </FsRadioButton>
                <FsRadioButton
                  value={DropdownOrder.BiggestPrice}
                  selected={selectedOrder === DropdownOrder.BiggestPrice}
                  onChange={() => {
                    setSelectedOrder(DropdownOrder.BiggestPrice);
                  }}
                >
                  Maior preço
                </FsRadioButton>
              </S.RadioButtonGroup>
            </S.FsAccordionFilter>
          </MobileView>
        </>
      )}
      {Object.entries(groupFilters(options || [])).map(([name, group], key) => (
        <S.FsAccordionFilter key={key} label={name} expanded headingLevel={3}>
          {!isClient ||
            (!group && (
              <div style={{ marginBottom: 'var(--spacing-2x)' }}>
                <FsLoading />
              </div>
            ))}
          <FsCheckboxGroup>
            {group.map((option) => {
              const renderOption = () => {
                if (option.attributes.displayAsFilterOnProductsList !== false) {
                  return (
                    <FsCheckbox
                      disabled={
                        blockedFilters
                          ? blockedFilters[option.attributes.slug]
                          : false
                      }
                      key={option.id}
                      onChange={() => {
                        handleOptions(option);
                        window.dataLayer.push({
                          event: 'genericEvent',
                          event_name: 'filter_interaction',
                          event_parameter_1: option.attributes.name,
                        });
                      }}
                      selected={selectedOptionsFilter[option.attributes.slug]}
                    >
                      {option.attributes.name}
                    </FsCheckbox>
                  );
                }
              };
              return <>{renderOption()}</>;
            })}
          </FsCheckboxGroup>
        </S.FsAccordionFilter>
      ))}
    </FsBarFilter>
  );
};
