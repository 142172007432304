import styled from 'styled-components';
import { media } from '@mf/common/utils/styles/index';

export const Content = styled.div`
  display: flex;
  height: 608px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;

export const DrawerFilterWrapper = styled.div`
  > div {
    ${media.lessThan('mobile')} {
      position: absolute;
      z-index: 1005;
      min-height: inherit;
      height: inherit;
    }
  }
`;
