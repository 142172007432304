import Link from 'next/link';
import { CardProduct, CardProductSkeleton } from '@mf/common/components/index';
import { useEffect } from 'react';
import { TProductsListing } from 'repositories/aecomStrapiCms/product/types';
import * as S from './ProductsList.styles';
import { useProductsListHooks } from './ProductsList.hooks';

interface ProductListProps {
  heading: string;
  listing: TProductsListing[];
  isFetching: boolean;
}

export const ProductsList = ({
  heading,
  listing,
  isFetching,
}: ProductListProps) => {
  const { getTotalSkeletons } = useProductsListHooks();

  useEffect(() => {
    const element = document.getElementById('productListing');
    const observer = new IntersectionObserver((element) => {
      const products = listing.flatMap((page) => page.data);
      if (element[0].isIntersecting) {
        const itemsMap = products.map((item) => ({
          item_id: item.attributes.final_product_id,
          item_name: item.attributes.name,
          affiliation: 'Printi',
          discount: 0,
          index: 0,
          item_brand: 'Printi',
          item_category: '',
          item_list_id: heading && heading.toLowerCase().replace(' ', '_'),
          item_list_name: heading,
          price: item?.attributes?.minimal_price?.toString() || '0',
          quantity: item.attributes.minimal_quantity,
        }));

        if (itemsMap.length > 0) {
          window.dataLayer.push({ ecommerce: null });
          window.dataLayer.push({
            event: 'view_item_list',
            event_name: 'view_item_list',
            item_list_id: heading && heading.toLowerCase().replace(' ', '_'),
            item_list_name: heading,
            ecommerce: {
              currency: 'BRL',
              items: itemsMap,
            },
          });
        }
      }
    });

    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, [listing, heading]);

  return (
    <S.Content id="productListing" className="product-listing">
      {(listing || []).flatMap((page) =>
        (page.data || []).map((product) => (
          <Link href={`/${product.attributes.slug}/`} key={product.id}>
            <CardProduct product={product} carrousel={true} />
          </Link>
        )),
      )}
      {isFetching &&
        Array.from({ length: getTotalSkeletons(listing) }).map((_, index) => (
          <CardProductSkeleton key={index} />
        ))}
    </S.Content>
  );
};
