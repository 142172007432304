'use client';

import { DesktopView, MobileView } from '@mf/common/components/index';
import {
  DropdownOrientation,
  FsButtonMini,
  FsDescription,
  FsDropdown,
  FsHeading,
  FsPopoverMenu,
  FsPopoverMenuItem,
  FsTopSection,
  HeadingSize,
  PopoverMenuItemType,
} from '@printi/ds-offset-react-core';
import { Dispatch, SetStateAction } from 'react';
// eslint-disable-next-line import/order
import { DropdownOrder } from '@/screens/main/Products';
import * as S from './ProductSubHeader.styles';
import { useProductSubHeaderHooks } from './ProductsSubHeader.hooks';

interface ProductSubHeaderProps {
  setOpenFilters: Dispatch<SetStateAction<boolean>>;
  selectedOrder: DropdownOrder;
  setSelectedOrder: Dispatch<SetStateAction<DropdownOrder>>;
  searchedProducts: number;
  totalProducts: number;
  totalSelectedFilters: number;
}

export const ProductSubHeader = ({
  setOpenFilters,
  selectedOrder,
  setSelectedOrder,
  searchedProducts,
  totalProducts,
  totalSelectedFilters,
}: ProductSubHeaderProps) => {
  const { handleOnSelected, getDescription } = useProductSubHeaderHooks({
    setSelectedOrder,
  });

  return (
    <S.SubHeader>
      <div className="fs-row">
        <div className="fs-col-md-3 fs-col-sm-6">
          <MobileView>
            <FsTopSection
              description={getDescription(searchedProducts, totalProducts)}
              heading="Produtos"
              showDescription
            />
          </MobileView>
          <DesktopView>
            <S.HeaderWrapper>
              <FsHeading size={HeadingSize.LG}>Produtos</FsHeading>
            </S.HeaderWrapper>
          </DesktopView>
        </div>
        <div className="fs-col-md-9 fs-col-sm-6 flex-end">
          <MobileView>
            <FsButtonMini onClick={() => setOpenFilters(true)}>
              Filtros {totalSelectedFilters ? `(${totalSelectedFilters})` : ''}
            </FsButtonMini>
          </MobileView>
          <DesktopView>
            <S.ProductsDropdownWrapper>
              <FsDescription>
                {getDescription(searchedProducts, totalProducts)}
              </FsDescription>
              <S.DropdownEnd>
                <FsDropdown
                  label={selectedOrder}
                  description={'Ordenar por'}
                  orientation={DropdownOrientation.Horizontal}
                  selected
                >
                  <FsPopoverMenu>
                    <FsPopoverMenuItem
                      label="Mais vendidos"
                      type={PopoverMenuItemType.Text}
                      value={DropdownOrder.BestSellers}
                      onSelected={handleOnSelected}
                    />
                    <FsPopoverMenuItem
                      label="Menor preço"
                      type={PopoverMenuItemType.Text}
                      value={DropdownOrder.LowestPrice}
                      onSelected={handleOnSelected}
                    />
                    <FsPopoverMenuItem
                      label="Maior preço"
                      type={PopoverMenuItemType.Text}
                      value={DropdownOrder.BiggestPrice}
                      onSelected={handleOnSelected}
                    />
                  </FsPopoverMenu>
                </FsDropdown>
              </S.DropdownEnd>
            </S.ProductsDropdownWrapper>
          </DesktopView>
        </div>
      </div>
    </S.SubHeader>
  );
};
