'use client';

import {
  BannerHeroBgColor,
  BannerHeroContentType,
  BannerHeroSize,
  ButtonSize,
  FsBannerHero,
  FsButton,
  FsDescription,
  FsHeading,
  HeadingSize,
  HeadingTag,
} from '@printi/ds-offset-react-core';
import { ComponentProps } from 'react';
import Image from 'next/image';
import { useBannerHooks } from './Banner.hooks';

type BannerProps = {
  description: string | null;
  heading: string;
  actionUrl: string;
  actionLabel: string;
  actionLabelIcon?: string;
  bannerImage: string | null;
  bannerImageAlt: string;
  inverseHeading?: boolean;
  inverseButton?: boolean;
  showCountdown?: boolean;
  backgroundColor?: BannerHeroBgColor;
  id?: string;
} & ComponentProps<typeof FsBannerHero>;

export const Banner = ({
  description,
  heading,
  actionUrl,
  actionLabel,
  actionLabelIcon,
  bannerImage,
  bannerImageAlt,
  inverseHeading,
  inverseButton,
  showCountdown,
  size,
  backgroundColor,
  id = '',
}: BannerProps) => {
  const { actionSlot, onZeroTime } = useBannerHooks();

  return (
    <FsBannerHero
      actionSlot={
        <FsButton
          size={ButtonSize.LG}
          onClick={() => actionSlot({ actionUrl })}
          icon={actionLabelIcon}
          inverse={inverseButton ?? false}
        >
          {actionLabel}
        </FsButton>
      }
      bannerImage={
        <Image src={bannerImage || ''} alt={bannerImageAlt || ''} fill />
      }
      contentSlot={
        <>
          <FsDescription inverse={inverseHeading ?? false}>
            {description || ''}
          </FsDescription>
          <FsHeading
            size={HeadingSize.LG}
            inverse={inverseHeading ?? false}
            tag={HeadingTag.SPAN}
          >
            {heading}
          </FsHeading>
        </>
      }
      contentType={BannerHeroContentType.Text}
      onZeroTime={onZeroTime}
      size={size || BannerHeroSize.SM}
      timerHours={1}
      timerLabel="Acaba em"
      timerMinutes={0}
      timerSeconds={0}
      showCountdown={showCountdown}
      backgroundColor={backgroundColor}
      id={id}
    />
  );
};
