export enum DropdownOrder {
  LowestPrice = 'Menor preço',
  BiggestPrice = 'Maior preço',
  BestSellers = 'Mais vendidos',
}

export type TSelectedOptionsFilter = {
  [slug: string]: boolean;
};

export type TBlockedFilters = {
  [slug: string]: boolean;
};
