import { useCallback } from 'react';
import { useProductsHooks } from '@/screens/main/Products';
import { TProductsListing } from 'repositories/aecomStrapiCms/product/types';

export const useProductsListHooks = () => {
  const { getSearchedProducts } = useProductsHooks();

  const getTotalSkeletons = useCallback(
    (listing: TProductsListing[]) => {
      if (!listing[0]) {
        return 3;
      }

      const searched = getSearchedProducts(listing);
      const total = listing[0].meta.pagination.total;
      const pageSize = listing[0].meta.pagination.pageSize;
      const missing = total - searched;

      if (missing > pageSize) {
        return pageSize;
      }

      return missing;
    },
    [getSearchedProducts],
  );

  return { getTotalSkeletons };
};
