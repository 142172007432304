import { api } from '@mf/common/config/axios/serviceApi';
import { useQuery } from '@tanstack/react-query';
import { AxiosInstance } from 'axios';
import { TAxiosDefaultConfig } from '../../defaults/types';
import { TClassifcationOption, TGetClassificationOptions } from './types';

const strapiClassificationOption = ({
  baseUrl,
  token,
}: TAxiosDefaultConfig) => {
  const apiCms: AxiosInstance = api({ baseUrl, token });

  const getClassificationOptions = async (): Promise<
    TClassifcationOption[]
  > => {
    const { data }: TGetClassificationOptions = await apiCms.get(
      '/v1/classification-options?pageSize=300&page=1',
    );

    return data.data;
  };

  const useClassificationOptions = () =>
    useQuery(['classification-options'], () => getClassificationOptions());

  return {
    getClassificationOptions,
    useClassificationOptions,
  };
};

export default strapiClassificationOption;
