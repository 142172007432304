import { media } from '@mf/common/utils/styles/index';
import styled from 'styled-components';

export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--spacing-6x) var(--spacing-3x);
  width: 100%;

  ${media.lessThan('mobile')} {
    grid-template-columns: repeat(2, 1fr);
    gap: var(--spacing-4x) var(--spacing-2x);
  }

  @media (max-width: 321px) {
    grid-template-columns: repeat(2, 0.75fr);
    gap: var(--spacing-4x) var(--spacing-1x);
  }
`;
