import { Dispatch, SetStateAction } from 'react';
import {
  ButtonGroupItemVariant,
  DrawerSizes,
  FsButtonGroup,
  FsButtonGroupItem,
  FsDrawer,
} from '@printi/ds-offset-react-core';
import {
  DropdownOrder,
  TBlockedFilters,
  TSelectedOptionsFilter,
} from '@/screens/main/Products';
import { ProductFilters } from '@/components/main/ProductFilters';
import useProductFiltersHooks from '@/components/main/ProductFilters/ProductFilters.hooks';
// eslint-disable-next-line import/order
import * as S from './DrawerProductsFilters.styles';

interface DrawerProductsFiltersProps {
  isOpen: boolean;
  setOpenFilters: Dispatch<SetStateAction<boolean>>;
  selectedOptionsFilter: TSelectedOptionsFilter;
  setSelectedOptionsFilter: Dispatch<SetStateAction<TSelectedOptionsFilter>>;
  searchedProducts: number;
  selectedOrder: DropdownOrder;
  setSelectedOrder: Dispatch<SetStateAction<DropdownOrder>>;
  blockedFilters?: TBlockedFilters;
}

export const DrawerProductsFilters = ({
  isOpen,
  setOpenFilters,
  selectedOptionsFilter,
  setSelectedOptionsFilter,
  searchedProducts,
  blockedFilters,
  selectedOrder,
  setSelectedOrder,
}: DrawerProductsFiltersProps) => {
  const { onClear } = useProductFiltersHooks({ setSelectedOptionsFilter });

  return (
    <S.DrawerFilterWrapper>
      <FsDrawer
        className="drawer-base-config"
        heading="Filtros"
        isOpen={isOpen}
        size={DrawerSizes.LG}
        showNavBar
        showFixedBar
        fixedBarProps={{
          actionSlot: (
            <FsButtonGroup size={'sm'}>
              <FsButtonGroupItem
                slot="primary"
                aria-label="Button"
                onClick={() => setOpenFilters(false)}
              >
                Filtrar {`(${searchedProducts})`}
              </FsButtonGroupItem>
              <FsButtonGroupItem
                slot="secondary"
                variant={ButtonGroupItemVariant.Secondary}
                onClick={onClear}
              >
                Limpar
              </FsButtonGroupItem>
            </FsButtonGroup>
          ),
        }}
        onClose={() => setOpenFilters(false)}
      >
        <S.Content>
          <ProductFilters
            selectedOptionsFilter={selectedOptionsFilter}
            setSelectedOptionsFilter={setSelectedOptionsFilter}
            blockedFilters={blockedFilters}
            selectedOrder={selectedOrder}
            setSelectedOrder={setSelectedOrder}
          />
        </S.Content>
      </FsDrawer>
    </S.DrawerFilterWrapper>
  );
};
