import media from '@mf/common/utils/styles/media';
// eslint-disable-next-line
import styled from 'styled-components';

export const Grid = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;

  gap: var(--spacing-20x);
  padding: 0 var(--spacing-4x) var(--spacing-4x);

  ${media.lessThan('mobile')} {
    padding: var(--spacing-none) var(--spacing-2x) var(--spacing-8x)
      var(--spacing-2x);
    align-items: flex-start;
    gap: var(--spacing-4x);
    align-self: stretch;
  }

  .filters {
    width: unset !important;
    transition: width 0.5s;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: flex-start;
  align-self: stretch;

  gap: var(--spacing-3x);

  ${media.lessThan('mobile')} {
    flex-direction: column;
    justify-content: center;
    gap: var(--spacing-2x);
    align-self: stretch;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-4x);
  flex: 1 0 0;
`;

export const Banner = styled.div`
  display: flex;
  height: var(--size-40x);
  min-height: var(--size-40x);
  max-height: var(--size-40x);
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
`;

export const Pagination = styled.div`
  display: flex;
  padding-top: var(--spacing-4x);
  justify-content: center;
  align-items: center;
  align-self: stretch;
`;
