'use client';

import { DesktopView, MobileView } from '@mf/common/components';
import { Banner } from '@mf/promotions/components/Banner';
import {
  BannerHeroBgColor,
  ButtonSecondarySize,
  FsButtonSecondary,
} from '@printi/ds-offset-react-core';
import { useEffect, useState } from 'react';
// import strapiBannerPromotion from '@mf/promotions/repositories/aecomStrapiCms/bannerPromotion/index';
import { useLayout } from '@mf/common/components/Media/Layout';
import strapiProduct from 'repositories/aecomStrapiCms/product';
import { DrawerProductsFilters } from '@components/main/DrawerProductsFilters/DrawerProductsFilters';
import { ProductFilters } from '@components/main/ProductFilters';
import { ProductSubHeader } from '@components/main/ProductSubHeader';
import { ProductsList } from '@components/main/ProductsList';
import useProductFiltersHooks from '@components/main/ProductFilters/ProductFilters.hooks';
import { useProductsHooks } from './Products.hooks';
import {
  DropdownOrder,
  TBlockedFilters,
  TSelectedOptionsFilter,
} from './Products.types';
import * as S from './Products.styles';

type TDisabledFilters = {
  [slug: string]: boolean;
};

export function ProductsScreen() {
  const [openFilters, setOpenFilters] = useState<boolean>(false);
  const [pageSize] = useState<number>(12);
  const [selectedOrder, setSelectedOrder] = useState<DropdownOrder>(
    DropdownOrder.BestSellers,
  );
  const [selectedOptionsFilter, setSelectedOptionsFilter] =
    useState<TSelectedOptionsFilter>({});
  const [blockedFilters, setBlockedFilters] = useState<TBlockedFilters>({});
  const [filtersWillBeBlocked, setFiltersWillBeBlocked] =
    useState<TBlockedFilters>({});
  const [isClient, setIsClient] = useState<boolean>(false);

  const { setShowCta, isMobile } = useLayout();
  const showBanner = true; //Used to hidden banner for v1
  setShowCta(false);
  const auth = {
    baseUrl: process.env.BFF_API_BASE_URL,
  };

  const { useProductsListing } = strapiProduct(auth);
  // const { useBannerPromotions } = strapiBannerPromotion(auth);

  const { getFiltersToQuery, getSortToQuery, getSearchedProducts } =
    useProductsHooks();

  const { getCountOptions } = useProductFiltersHooks({
    setSelectedOptionsFilter,
  });

  // eslint-disable-next-line
  // const { data: banners } = useBannerPromotions();

  const {
    data: products,
    fetchNextPage,
    isFetchingNextPage,
    isFetching,
    hasNextPage,
  } = useProductsListing({
    params: {
      pagination: { page: 0, pageSize: pageSize },
      filters: {
        classification_options: {
          slug: {
            $contains: getFiltersToQuery(selectedOptionsFilter),
          },
        },
      },
      sort: [getSortToQuery(selectedOrder), { name: 'asc' }],
      populate: ['banner_image', 'small_image', 'small_image_hover'],
    },
  });

  useEffect(() => {
    if (products?.pages[0]?.meta.pagination.total === 0) {
      const filtersWillBeBlocked = Object.keys(selectedOptionsFilter).reduce(
        (acc, slug) => {
          if (selectedOptionsFilter[slug]) {
            acc[slug] = true;
          }
          return acc;
        },
        {} as TDisabledFilters,
      );

      setFiltersWillBeBlocked((prev) => ({
        ...prev,
        ...filtersWillBeBlocked,
      }));
    }
  }, [selectedOptionsFilter, products?.pages]);

  useEffect(() => {
    const newDisabledFilters = Object.keys(selectedOptionsFilter).reduce(
      (acc, slug) => {
        if (selectedOptionsFilter[slug] === false) {
          acc[slug] = filtersWillBeBlocked[slug] === true;
        }
        return acc;
      },
      {} as TDisabledFilters,
    );

    setBlockedFilters((prev) => ({
      ...prev,
      ...newDisabledFilters,
    }));
  }, [filtersWillBeBlocked, selectedOptionsFilter]);

  useEffect(() => {
    setIsClient(true);
  }, []);

  return (
    <>
      <ProductSubHeader
        setOpenFilters={setOpenFilters}
        selectedOrder={selectedOrder}
        setSelectedOrder={setSelectedOrder}
        totalProducts={products?.pages[0]?.meta.pagination.total || 0}
        searchedProducts={getSearchedProducts(products?.pages || [])}
        totalSelectedFilters={getCountOptions(selectedOptionsFilter)}
      />
      <S.Grid>
        <S.Content>
          <div className="fs-row">
            <div
              className="fs-col-md-4 fs-col-lg-3"
              style={{
                display: isClient && isMobile ? 'none' : 'block',
              }}
            >
              <ProductFilters
                selectedOrder={selectedOrder}
                setSelectedOrder={setSelectedOrder}
                selectedOptionsFilter={selectedOptionsFilter}
                setSelectedOptionsFilter={setSelectedOptionsFilter}
                blockedFilters={blockedFilters}
              />
            </div>
            <div className="fs-col-md-8 fs-col-lg-9 fs-col-sm-12">
              <S.ContentWrapper>
                {!showBanner && (
                  <DesktopView>
                    {!getCountOptions(selectedOptionsFilter) && (
                      <S.Banner>
                        <Banner
                          backgroundColor={BannerHeroBgColor.Neon}
                          description="25% off"
                          heading="Camisetas"
                          actionUrl="/categorias/camisetas"
                          actionLabel="Aproveitar"
                          bannerImageAlt="Banner de promoção de camisetas"
                          bannerImage="https://d1br4h274rc9sc.cloudfront.net/content/5e92bbdcfd4a69b680d9c457866be4c0_ef78431981.png"
                        />
                      </S.Banner>
                    )}
                  </DesktopView>
                )}
                <ProductsList
                  heading="Produtos"
                  isFetching={isFetchingNextPage || isFetching}
                  listing={products?.pages || []}
                />
                {hasNextPage && !!products?.pages[0]?.meta.pagination.total && (
                  <S.Pagination>
                    <FsButtonSecondary
                      loading={isFetchingNextPage}
                      onClick={() => fetchNextPage()}
                      size={ButtonSecondarySize.LG}
                    >
                      Carregar mais
                    </FsButtonSecondary>
                  </S.Pagination>
                )}
              </S.ContentWrapper>
            </div>
          </div>
        </S.Content>
      </S.Grid>
      <MobileView>
        <DrawerProductsFilters
          isOpen={openFilters}
          setOpenFilters={setOpenFilters}
          selectedOptionsFilter={selectedOptionsFilter}
          setSelectedOptionsFilter={setSelectedOptionsFilter}
          searchedProducts={products?.pages[0]?.meta.pagination.total || 0}
          blockedFilters={blockedFilters}
          selectedOrder={selectedOrder}
          setSelectedOrder={setSelectedOrder}
        />
      </MobileView>
    </>
  );
}
