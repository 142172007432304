import styled from 'styled-components';
import { FsAccordion } from '@printi/ds-offset-react-core';
import { media } from '@mf/common/utils/styles/index';

export const RadioButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

export const FsAccordionFilter = styled(FsAccordion)`
  ${media.lessThan('mobile')} {
    &:hover:not([aria-expanded='true']) {
      background-color: transparent;
    }
  }
`;
