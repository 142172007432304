'use client';
import { useRouter } from 'next/navigation';
import { useCallback } from 'react';

interface ActionSlotProps {
  actionUrl: string;
}

export const useBannerHooks = () => {
  const { push } = useRouter();

  const actionSlot = useCallback(
    ({ actionUrl }: ActionSlotProps) => {
      push(actionUrl);
    },
    [push],
  );

  const onZeroTime = useCallback(() => {
    // eslint-disable-next-line
    console.log('The promotion is over!');
  }, []);

  return {
    actionSlot,
    onZeroTime,
  };
};
